import React, { useState, useEffect } from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import './Download.css';

function Download() {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Change this to set how many items per page

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    fetch('http://cms.innovationpowerem.com:3001/api/fetchData')
      .then(response => response.json())
      .then(data => {
        console.log('Data received:', data);
        setData(data);
      })
      .catch(error => console.error('Error fetching data:', error));
  };

  // Calculate total pages
  const totalPages = Math.ceil(data.length / itemsPerPage);
  
  // Get current items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  // Export table to PDF
  const exportPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({ 
      head: [['Document Number', 'Revision Number', 'Date', 'Request Number', 'Location', 'Equipment ID', 'Work Description', 'Points of Isolation', 'Primary Earths', 'Additional Earths', 'Circuits Affected', 'Work Duration', 'Notice Period', 'Work Requested By', 'Telephone', 'Authorized Person', 'Approval Date', 'Comments', 'SCADA Comments', 'Control Room Engineer Signature', 'Control Room Engineer Date', 'Shift Control Manager Signature', 'Shift Control Manager Date']],
      body: data.map(item => [item.docNo, item.revNo, item.date, item.requestNo, item.location, item.equipmentId, item.workDescription, item.pointsOfIsolation, item.primaryEarths, item.additionalEarths, item.circuitsAffected, item.workDuration, item.noticePeriod, item.workRequestedBy, item.tel, item.authorizedPerson, item.approvalDate, item.comments, item.scadaComments, item.controlRoomEngineerSignature, item.controlRoomEngineerDate, item.shiftControlManagerSignature, item.shiftControlManagerDate]),
    });
    doc.save('table.pdf');
  };

  return (
    <div className="container mx-auto p-4 ml-[30%]">
      <div className="mb-4 text-center">
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2" onClick={exportPDF}>PDF</button>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-300">
          <thead>
            <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
              <th className="py-3 px-6 text-left">Document Number</th>
              <th className="py-3 px-6 text-left">Revision Number</th>
              <th className="py-3 px-6 text-left">Date</th>
              <th className="py-3 px-6 text-left">Request Number</th>
              <th className="py-3 px-6 text-left">Location</th>
              <th className="py-3 px-6 text-left">Equipment ID</th>
              <th className="py-3 px-6 text-left">Work Description</th>
              <th className="py-3 px-6 text-left">Points of Isolation</th>
              <th className="py-3 px-6 text-left">Primary Earths</th>
              <th className="py-3 px-6 text-left">Additional Earths</th>
              <th className="py-3 px-6 text-left">Circuits Affected</th>
              <th className="py-3 px-6 text-left">Work Duration</th>
              <th className="py-3 px-6 text-left">Notice Period</th>
              <th className="py-3 px-6 text-left">Work Requested By</th>
              <th className="py-3 px-6 text-left">Telephone</th>
              <th className="py-3 px-6 text-left">Authorized Person</th>
              <th className="py-3 px-6 text-left">Approval Date</th>
              <th className="py-3 px-6 text-left">Comments</th>
              <th className="py-3 px-6 text-left">SCADA Comments</th>
              <th className="py-3 px-6 text-left">Control Room Engineer Signature</th>
              <th className="py-3 px-6 text-left">Control Room Engineer Date</th>
              <th className="py-3 px-6 text-left">Shift Control Manager Signature</th>
              <th className="py-3 px-6 text-left">Shift Control Manager Date</th>
            </tr>
          </thead>
          <tbody className="text-gray-600 text-sm font-light">
            {currentItems.map((item, index) => (
              <tr key={index} className="border-b border-gray-200 hover:bg-gray-100">
                <td className="py-3 px-6 text-left whitespace-nowrap">{item.docNo}</td>
                <td className="py-3 px-6 text-left">{item.revNo}</td>
                <td className="py-3 px-6 text-left">{item.date}</td>
                <td className="py-3 px-6 text-left">{item.requestNo}</td>
                <td className="py-3 px-6 text-left">{item.location}</td>
                <td className="py-3 px-6 text-left">{item.equipmentId}</td>
                <td className="py-3 px-6 text-left">{item.workDescription}</td>
                <td className="py-3 px-6 text-left">{item.pointsOfIsolation}</td>
                <td className="py-3 px-6 text-left">{item.primaryEarths}</td>
                <td className="py-3 px-6 text-left">{item.additionalEarths}</td>
                <td className="py-3 px-6 text-left">{item.circuitsAffected}</td>
                <td className="py-3 px-6 text-left">{item.workDuration}</td>
                <td className="py-3 px-6 text-left">{item.noticePeriod}</td>
                <td className="py-3 px-6 text-left">{item.workRequestedBy}</td>
                <td className="py-3 px-6 text-left">{item.tel}</td>
                <td className="py-3 px-6 text-left">{item.authorizedPerson}</td>
                <td className="py-3 px-6 text-left">{item.approvalDate}</td>
                <td className="py-3 px-6 text-left">{item.comments}</td>
                <td className="py-3 px-6 text-left">{item.scadaComments}</td>
                <td className="py-3 px-6 text-left">{item.controlRoomEngineerSignature}</td>
                <td className="py-3 px-6 text-left">{item.controlRoomEngineerDate}</td>
                <td className="py-3 px-6 text-left">{item.shiftControlManagerSignature}</td>
                <td className="py-3 px-6 text-left">{item.shiftControlManagerDate}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex mt-4 justify-center">
        <button 
          onClick={() => setCurrentPage(currentPage - 1)} 
          disabled={currentPage === 1}
          className="bg-blue-800 hover:bg-gray-400 text-white font-bold py-2 px-4 rounded disabled:opacity-50"
        >
          Previous
        </button>
        <span className="mx-4">{currentPage} of {totalPages}</span>
        <button 
          onClick={() => setCurrentPage(currentPage + 1)} 
          disabled={currentPage === totalPages}
          className="bg-blue-800 hover:bg-gray-400 text-white font-bold py-2 px-4 rounded disabled:opacity-50"
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default Download;





