import React from 'react'
import { Outlet } from 'react-router-dom'


const App = () => {
  return (
    <>
     
     <Outlet/> 
    
    </>
  )
}

export default App
