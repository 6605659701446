import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi'; // Import icons from react-icons library
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AdbIcon from '@mui/icons-material/Adb';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import AirplaneTicketIcon from '@mui/icons-material/AirplaneTicket';
import AssistantIcon from '@mui/icons-material/Assistant';
import ApiIcon from '@mui/icons-material/Api';
import AddReactionIcon from '@mui/icons-material/AddReaction';
import LOGO123 from "../imges/LOGO123.png"
import ExitToAppIcon from '@mui/icons-material/ExitToApp';



const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [openSections, setOpenSections] = useState({
    permitSystem: false,
    preventiveMaintenance: false,
    hscModule: false,
    userManagement: false,
    download:false,
  });

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const toggleSection = (section) => {
    setOpenSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  return (
    <div className="fixed top-0 left-0 h-screen shadow-lg rounded-lg ">
      {/* Sidebar toggle button for mobile */}
      <button
        onClick={toggleSidebar}
        className="absolute top-4 left-4 p-2 bg-blue-500 text-white rounded-md shadow-md z-10 lg:hidden"
      >
        {isOpen ? <ArrowBackIcon /> : <ArrowForwardIcon />}
      </button>

      {/* Sidebar content */}
      <div
        className={`fixed top-0 left-0 w-64 bg-blue-900 text-white p-4 border-r border-gray-300 transition-transform transform ${
          isOpen ? 'translate-x-0' : '-translate-x-full'
        } lg:translate-x-0 lg:w-64 lg:static lg:block shadow-xl z-10 h-screen overflow-y-auto`}
      >
        <div className="text-center text-lg font-semibold mb-4  rounded-lg">
        <img src={LOGO123} alt="wow" style={{ height: '40px', width: "40%", display: 'block', margin: '0 auto',borderRadius: "50px" }} />



          <button className="absolute top-4 right-4 lg:hidden" onClick={toggleSidebar}>
            <FiChevronLeft />
          </button>
        </div>
        <ul className="space-y-2">
          <li>
            {/* Sidebar section with toggle button */}
            <h2
              onClick={() => toggleSection('permitSystem')}
              className="w-full text-left cursor-pointer flex items-center justify-between shadow-xl"
            >
              <span><AvTimerIcon/>Permit System</span>
              <span>{openSections.permitSystem ? <FiChevronLeft /> : <FiChevronRight />}</span>
            </h2>
            {/* Nested links for each section */}
            {openSections.permitSystem && (
              <ul className="ml-4 space-y-1">
                <li>
                  <Link to="afw-form">AFW form</Link>
                </li>
                <li>
                  <Link to="/limited-access-permit">Limited access permit</Link>
                </li>
                <li>
                  <Link to="/sanction-for-test">Sanction for Test</Link>
                </li>
                <li>
                  <Link to="/permit-to-work">Permit to work</Link>
                </li>
                <li>
                  <Link to="/general-permit">General Permit</Link>
                </li>
                <li>
                  <Link to="ptw-form">PTW</Link>
                </li>
              </ul>
            )}
          </li>
          {/* Repeat similar structure for other sections */}
          <li>
            <h2
              onClick={() => toggleSection('preventiveMaintenance')}
              className="w-full text-left cursor-pointer flex items-center justify-between shadow-xl"
            >
              <span>< AdbIcon/>Preventive Maintenance</span>
              <span>{openSections.preventiveMaintenance ? <FiChevronLeft /> : <FiChevronRight />}</span>
            </h2>
            {openSections.preventiveMaintenance && (
              <ul className="ml-4 space-y-1">
                <li>
                  <Link to="/maintenance-schedule">Maintenance schedule</Link>
                </li>
                <li>
                  <Link to="/checklist">Checklist</Link>
                </li>
              </ul>
            )}
          </li>
          <li>
            <h2
              onClick={() => toggleSection('hscModule')}
              className="w-full text-left cursor-pointer flex items-center justify-between shadow-xl"
            >
              <span> <AdminPanelSettingsIcon/>HSC Module</span>
              <span>{openSections.hscModule ? <FiChevronLeft /> : <FiChevronRight />}</span>
            </h2>
            {openSections.hscModule && (
              <ul className="ml-4 space-y-1">
                <li>
                  <Link to="/incident-reporting">Incident reporting</Link>
                </li>
                <li>
                  <Link to="/observation">Observation</Link>
                </li>
                <li>
                  <Link to="/audit">Audit</Link>
                </li>
                <li>
                  <Link to="/training">Training</Link>
                </li>
              </ul>
            )}
          </li>
          <li>
            <h2
              onClick={() => toggleSection('userManagement')}
              className="w-full text-left cursor-pointer flex items-center justify-between shadow-xl"
            >
              <span>< AccountBalanceIcon/>User Management</span>
              <span>{openSections.userManagement ? <FiChevronLeft /> : <FiChevronRight />}</span>
            </h2>
            {openSections.userManagement && (
              <ul className="ml-4 space-y-1">
                <li>
                  <Link to="/create">Create</Link>
                </li>
                <li>
                  <Link to="/update">Update</Link>
                </li>
                <li>
                  <Link to="/delete">Delete</Link>
                </li>
                <li>
                  <Link to="/password-change">Password change</Link>
                </li>
                <li>
                  <Link to="/permission-role">Permission (role)</Link>
                </li>
              </ul>
            )}
          </li>
          {/* Other sidebar links */}
          <li>
            <Link to="/material-management">  <AssistantIcon/>Material Management</Link>
          </li>
          <li>
            <Link to="/analytical-module"> < ApiIcon/> Analytical Module</Link>
          </li>
          <li>
            <Link to="/settings"><AddReactionIcon/>Settings</Link>
          </li>
        </ul>



      
            <h2
              onClick={() => toggleSection('download')}
              className="w-full text-left cursor-pointer flex items-center justify-between shadow-xl"
            >
              <span><AirplaneTicketIcon/>Download</span>
              <span>{openSections.userManagement ? <FiChevronLeft /> : <FiChevronRight />}</span>
            </h2>
            {openSections.download && (
              <ul className="ml-4 space-y-1"> 
              <li>
                <Link to="fetchData"> AWF</Link>
               </li>
                <li>
                  <Link to="/update">PTW</Link>
                </li> 
              </ul>
            )}
         
                <div className='mt-10'>
                 {/* <button onClick={  }>Logout<ExitToAppIcon/> </button> */}
                 </div>

      </div>
    </div>
  );
};

export default Sidebar;






