import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import solarImage from '../imges/solar2.jpg';

const Register = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    cpassword: ""
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(formData);
    try {
      const response = await axios.post('http://cms.innovationpowerem.com:3001/register', formData, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      console.log('User registered successfully', response.data);

    } catch (error) {
      console.error('Error registering user:', error.message);
    } finally {
      setLoading(false);
      setFormData({
        name: '',
        email: '',
        password: '',
        cpassword: ''
      });
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8" style={{ backgroundImage: `url(${solarImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
      <div className="max-w-md w-80% space-y-8 bg-white bg-opacity-70 p-8 rounded-lg shadow-lg">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Create a new account</h2>
        </div>
        <form onSubmit={handleSubmit} className="mt-8 space-y-6">
          <input
            type="text"
            name="name"
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full p-3 sm:text-sm border-gray-300 rounded-md"
            placeholder="Full name"
            required
            value={formData.name}
            onChange={handleChange}
          />
          <input
            type="email"
            name="email"
            className="mt-3 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full p-3 sm:text-sm border-gray-300 rounded-md"
            placeholder="Email address"
            required
            value={formData.email}
            onChange={handleChange}
          />
          <input
            type="password"
            name="password"
            className="mt-3 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full p-3 sm:text-sm border-gray-300 rounded-md"
            placeholder="Password"
            required
            value={formData.password}
            onChange={handleChange}
          />
          <input
            type="password"
            name="cpassword"
            className="mt-3 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full p-3 sm:text-sm border-gray-300 rounded-md"
            placeholder="Confirm password"
            required
            value={formData.cpassword}
            onChange={handleChange}
          />
          <button
            type="submit"
            disabled={loading}
            className="mt-6 w-full bg-indigo-600 border border-transparent rounded-md py-3 px-4 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {loading ? 'Registering...' : 'Register'}
          </button>
        </form>
        <div className="text-center mt-4">
          <p className="text-sm text-gray-600">
            Already have an account?
            <Link to="/" className="font-medium text-indigo-600 hover:text-indigo-500">
              Log in here
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Register;





