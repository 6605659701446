import React from 'react'
import { Link } from 'react-router-dom';


const Not = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100 text-center">
      <h1 className="text-6xl font-bold text-red-600">404</h1>
      <h2 className="text-3xl font-semibold mt-4">Page Not Found</h2>
      <p className="text-lg mt-2">Oops! The page you are looking for doesn’t exist.</p>
      <Link to="/" className="mt-6 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 transition">
        Go Back Home
      </Link>
    </div>
  )
}

export default Not