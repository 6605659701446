
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import App from './App';
import Login from './Pages/Login';
import Register from './Pages/Register';
import Home from './Pages/Home';
import "./index.css";
import FormComponent from './Components/FormComponent';
import Download from './Pages/Download';
import Ptw from './Components/Ptw';
import Not from "./Pages/Not"

const root = createRoot(document.getElementById("root"));

// const routes =( [

//   {
//     path: "",
//     element: <App />,
//     children: [
       
//       { path: '', element: <Login/> },
//       { path: '/register', element: <Register /> },
//       { path: '/afw-form', element: <FormComponent /> },
//       // { path: '/home', element: <Home />},
//       {  path: "/fetchData"  ,element: <Download/>}
//     ]}
// ]);
   
const routes = [    
  { 
    path: '',
    element: <Login />
  },
  { 
    path: '/register',
    element: <Register />
  },
  {  
    path: '*',
    element: <Not/>
  },
  { 
    path: '/home',
    element: <Home />,
    children: [
      { 
        path: 'afw-form',
        element: <FormComponent />
      },
      {  
        path: 'fetchData',
        element: <Download />
      },
      {  
        path: 'ptw-form',
        element: <Ptw/>
      },  
  

    ]
  }
];





const router = createBrowserRouter(routes)

root.render(
  <RouterProvider router={router}>
  <App />
  </RouterProvider>
);





