
import React from 'react'
import  SideBar from "../Components/SideBar"
import { Outlet } from 'react-router-dom'

const Home = () => {
  return (
    <>
    <SideBar/>
    <Outlet/>
    </>
  )
}

export default Home