import React, { useState } from 'react';

const Ptw = () => {
  const [formData, setFormData] = useState({
    ptwNo: '',
    afw: '',
    workLocation: '',
    date: new Date().toISOString().slice(0, 10),
    natureOfWork: '',
    equipmentId: '',
    msAndRa: '',
    pointOfIsolation: 'Auto Selected', // Default values
    primaryEarthApplied: 'Auto Selected',
    lotoNumber: '',
    shift: '',
    permitReceiver: '',
    companyName: '',
    contactNumber: '',
    designationReceiver: '',
    namePermitIssuer: '',
    emergencyContactNumber: '911', // Fixed value example
    permitIssuer: '',
    designationPermitIssuer: '',
    numberOfEmployees: '',
    permitValidity: '8 hrs from approval',
    highRiskActivity: false,
    receiptReceiver: '',
    beforeSigningDetails: '',
    approvedByIssuer: '',
    permitIssuerComments: '',
    hazardsAssessment: false,
    hazardControlsRequired: false,
    leadWorkPartyDeclaration: '',
    shiftControlManagerSignature: '',
    subcontractorSignature: '',
    permitClosure: '',
    clearanceReceiver: '',
    closingIssuer: '',
    stopWorkNotice: '',
    reasonForCancellation: '',
    nameSignatureDate: ''
  });

  

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('http://cms.innovationpowerem.com:3001/api/ptw-form', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        // Handle success, e.g., show a success message or redirect
        console.log('Form data saved successfully!');
      } else {
        // Handle error
        console.error('Failed to save form data.');
      }
    } catch (error) {
      console.error('Error saving form data:', error);
    }
  };

  return (
    <div className="max-w-4xl mx-auto bg-white p-6 rounded-md shadow-md ml-[25%] ">
      <h2 className=" font-semibold mb-8 text-center p-5 bg-blue-700 text-2xl">Work Permit Form</h2>
      <form onSubmit={handleSubmit}>

        {/* Grid layout for form fields */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">

  
            <label htmlFor="ptwNo" className="block text-sm font-medium text-gray-700 mb-1">PTW No</label>
            <input type="text" id="ptwNo" name="ptwNo" value={formData.ptwNo} onChange={handleChange} className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" required />
          </div>

          {/* AFW (Job Card) */}
          <div>
            <label htmlFor="afw" className="block text-sm font-medium text-gray-700 mb-1">AFW (Job Card)</label>
            <input type="text" id="afw" name="afw" value={formData.afw} onChange={handleChange} className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" />
          </div>

          {/* Work Location */}
          <div>
            <label htmlFor="workLocation" className="block text-sm font-medium text-gray-700 mb-1">Work Location</label>
            <select id="workLocation" name="workLocation" value={formData.workLocation} onChange={handleChange} className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" required>
              <option value="">Select Work Location</option>
              <option value="Location A">Location A</option>
              <option value="Location B">Location B</option>
            </select>
          </div>

          {/* Date */}
          <div>
            <label htmlFor="date" className="block text-sm font-medium text-gray-700 mb-1">Date</label>
            <input type="date" id="date" name="date" value={formData.date} onChange={handleChange} className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" required />
          </div>

          {/* Nature of Work */}
          <div className="col-span-2">
            <label htmlFor="natureOfWork" className="block text-sm font-medium text-gray-700 mb-1">Nature of Work</label>
            <textarea id="natureOfWork" name="natureOfWork" value={formData.natureOfWork} onChange={handleChange} rows="3" className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" required></textarea>
          </div>

          {/* Equipment Identification Number */}
          <div>
            <label htmlFor="equipmentId" className="block text-sm font-medium text-gray-700 mb-1">Equipment Identification Number</label>
            <select id="equipmentId" name="equipmentId" value={formData.equipmentId} onChange={handleChange} className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" required>
              <option value="">Select Equipment ID</option>
              <option value="EQ123">EQ123</option>
              <option value="EQ456">EQ456</option>
            </select>
          </div>

          {/* MS & RA */}
          <div>
            <label htmlFor="msAndRa" className="block text-sm font-medium text-gray-700 mb-1">MS & RA</label>
            <select id="msAndRa" name="msAndRa" value={formData.msAndRa} onChange={handleChange} className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" required>
              <option value="">Select MS & RA</option>
              <option value="MS1">MS1</option>
              <option value="RA2">RA2</option>
            </select>
          </div>

          {/* Point of Isolation */}
          <div>
            <label htmlFor="pointOfIsolation" className="block text-sm font-medium text-gray-700 mb-1">Point of Isolation</label>
            <input type="text" id="pointOfIsolation" name="pointOfIsolation" value={formData.pointOfIsolation} onChange={handleChange} className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" readOnly />
          </div>

          {/* Primary Earth Applied at */}
          <div>
            <label htmlFor="primaryEarthApplied" className="block text-sm font-medium text-gray-700 mb-1">Primary Earth Applied at</label>
            <input type="text" id="primaryEarthApplied" name="primaryEarthApplied" value={formData.primaryEarthApplied} onChange={handleChange} className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" readOnly />
          </div>

          {/* LOTO Number */}
          <div>
            <label htmlFor="lotoNumber" className="block text-sm font-medium text-gray-700 mb-1">LOTO Number</label>
            <input type="text" id="lotoNumber" name="lotoNumber" value={formData.lotoNumber} onChange={handleChange} className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" required />
          </div>

          {/* Shift */}
          <div>
            <label htmlFor="shift" className="block text-sm font-medium text-gray-700 mb-1">Shift</label>
            <select id="shift" name="shift" value={formData.shift} onChange={handleChange} className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" required>
              <option value="">Select Shift</option>
              <option value="Morning">Morning</option>
              <option value="Night">Night</option>
            </select>
          </div>

          {/* Permit Receiver */}
          <div>
            <label htmlFor="permitReceiver" className="block text-sm font-medium text-gray-700 mb-1">Permit Receiver</label>
            <input type="text" id="permitReceiver" name="permitReceiver" value={formData.permitReceiver} onChange={handleChange} className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" required />
          </div>

          {/* Company Name */}
          <div>
            <label htmlFor="companyName" className="block text-sm font-medium text-gray-700 mb-1">Company Name</label>
            <input type="text" id="companyName" name="companyName" value={formData.companyName} onChange={handleChange} className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" required />
          </div>

          {/* Contact Number */}
          <div>
            <label htmlFor="contactNumber" className="block text-sm font-medium text-gray-700 mb-1">Contact Number</label>
            <input type="text" id="contactNumber" name="contactNumber" value={formData.contactNumber} onChange={handleChange} className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" required />
          </div>

          {/* Designation (Receiver) */}
          <div>
            <label htmlFor="designationReceiver" className="block text-sm font-medium text-gray-700 mb-1">Designation (Receiver)</label>
            <input type="text" id="designationReceiver" name="designationReceiver" value={formData.designationReceiver} onChange={handleChange} className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" required />
          </div>

          {/* Name Permit Issuer */}
          <div>
            <label htmlFor="namePermitIssuer" className="block text-sm font-medium text-gray-700 mb-1">Name Permit Issuer</label>
            <input type="text" id="namePermitIssuer" name="namePermitIssuer" value={formData.namePermitIssuer} onChange={handleChange} className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" required />
          </div>

          {/* Emergency Contact Number */}
          <div>
            <label htmlFor="emergencyContactNumber" className="block text-sm font-medium text-gray-700 mb-1">Emergency Contact Number</label>
            <input type="text" id="emergencyContactNumber" name="emergencyContactNumber" value={formData.emergencyContactNumber} onChange={handleChange} className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" required />
          </div>

          {/* Permit Issuer */}
          <div>
            <label htmlFor="permitIssuer" className="block text-sm font-medium text-gray-700 mb-1">Permit Issuer</label>
            <input type="text" id="permitIssuer" name="permitIssuer" value={formData.permitIssuer} onChange={handleChange} className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" required />
          </div>

          {/* Designation (Permit Issuer) */}
          <div>
            <label htmlFor="designationPermitIssuer" className="block text-sm font-medium text-gray-700 mb-1">Designation (Permit Issuer)</label>
            <input type="text" id="designationPermitIssuer" name="designationPermitIssuer" value={formData.designationPermitIssuer} onChange={handleChange} className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" required />
          </div>

          {/* Number of Employees */}
          <div>
            <label htmlFor="numberOfEmployees" className="block text-sm font-medium text-gray-700 mb-1">Number of Employees</label>
            <input type="text" id="numberOfEmployees" name="numberOfEmployees" value={formData.numberOfEmployees} onChange={handleChange} className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" required />
          </div>

          {/* Permit Validity */}
          <div>
            <label htmlFor="permitValidity" className="block text-sm font-medium text-gray-700 mb-1">Permit Validity</label>
            <input type="text" id="permitValidity" name="permitValidity" value={formData.permitValidity} onChange={handleChange} className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" required />
          </div>

          {/* High Risk Activity */}
          <div className="col-span-2">
            <div className="flex items-center">
              <input type="checkbox" id="highRiskActivity" name="highRiskActivity" checked={formData.highRiskActivity} onChange={handleChange} className="form-checkbox h-5 w-5 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" />
              <label htmlFor="highRiskActivity" className="ml-2 block text-sm text-gray-900">High Risk Activity</label>
            </div>
          </div>

          {/* Receipt Receiver */}
          <div>
            <label htmlFor="receiptReceiver" className="block text-sm font-medium text-gray-700 mb-1">Receipt Receiver</label>
            <input type="text" id="receiptReceiver" name="receiptReceiver" value={formData.receiptReceiver} onChange={handleChange} className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" />
          </div>

          {/* Before Signing Details */}
          <div className="col-span-2">
            <label htmlFor="beforeSigningDetails" className="block text-sm font-medium text-gray-700 mb-1">Before Signing Details</label>
            <textarea id="beforeSigningDetails" name="beforeSigningDetails" value={formData.beforeSigningDetails} onChange={handleChange} rows="3" className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"></textarea>
          </div>

          {/* Approved By (Issuer) */}
          <div>
            <label htmlFor="approvedByIssuer" className="block text-sm font-medium text-gray-700 mb-1">Approved By (Issuer)</label>
            <input type="text" id="approvedByIssuer" name="approvedByIssuer" value={formData.approvedByIssuer} onChange={handleChange} className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" />
          </div>

          {/* Permit Issuer Comments */}
          <div className="col-span-2">
            <label htmlFor="permitIssuerComments" className="block text-sm font-medium text-gray-700 mb-1">Permit Issuer Comments</label>
            <textarea id="permitIssuerComments" name="permitIssuerComments" value={formData.permitIssuerComments} onChange={handleChange} rows="3" className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"></textarea>
          </div>

          {/* Hazards Assessment */}
          <div className="col-span-2">
            <div className="flex items-center">
              <input type="checkbox" id="hazardsAssessment" name="hazardsAssessment" checked={formData.hazardsAssessment} onChange={handleChange} className="form-checkbox h-5 w-5 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" />
              <label htmlFor="hazardsAssessment" className="ml-2 block text-sm text-gray-900">Hazards Assessment</label>
            </div>
          </div>

          {/* Hazard Controls Required */}
          <div className="col-span-2">
            <div className="flex items-center">
              <input type="checkbox" id="hazardControlsRequired" name="hazardControlsRequired" checked={formData.hazardControlsRequired} onChange={handleChange} className="form-checkbox h-5 w-5 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" />
              <label htmlFor="hazardControlsRequired" className="ml-2 block text-sm text-gray-900">Hazard Controls Required</label>
            </div>
          </div>

          {/* Add Approval Button */}
          <div className="col-span-2 flex justify-center">
            <button type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Add Approval
            </button>
          </div>
        </form>
      </div>
    
    
  )
}

export default Ptw;

