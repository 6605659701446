import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';


const FormComponent = () => {
  const [loading ,setLoading] =useState(false)
  const nav =useNavigate()
  const currentDate = new Date().toISOString().split('T')[0]; 
  const [formData, setFormData] = useState({
    docNo: '',
    revNo: '',
    date: currentDate,
    requestNo: '',
    location: '',
    equipmentId: '',
    workDescription: '',
    pointsOfIsolation: '',
    primaryEarths: '',
    additionalEarths: '',
    circuitsAffected: '',
    workDuration: '',
    noticePeriod: '',
    workRequestedBy: '',
    tel: '',
    authorizedPerson: '',
    approvalDate: currentDate,
    comments: '',
    scadaComments: '',
    controlRoomEngineerSignature: '',
    controlRoomEngineerDate: currentDate,
    shiftControlManagerSignature: '',
    shiftControlManagerDate: currentDate,
  });

  console.log(formData)

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Step 3: Define handleSubmit function to send data to backend
  const handleSubmit = async (e) => {
    e.preventDefault();
     setLoading(true)
    try {
      // Example URL - replace with your actual backend endpoint
      const url = 'http://cms.innovationpowerem.com:3001/api/afw-form';
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        // Handle success, e.g., show a success message
        console.log('Form submitted successfully');
      } else {
        // Handle errors, e.g., show an error message
        console.error('Failed to submit form');
      }
      setLoading(false)
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const handleNav = () => {
    nav("/home"); // Navigate to /home route
  };


  return (
    <div className="p-4 bg-gray-100 rounded-md shadow-md max-w-lg mx-auto ">
      <button onClick={handleNav}>Go Back</button>
      <h1 className="text-[250%] font-bold mb-4 text-white bg-blue-600 p-2 rounded flex  justify-center h-[80px] aline align-middle ">AFW Page</h1>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Doc No:</label>
          <input
            type="number"
            name="docNo"
            value={formData.docNo}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Rev No:</label>
          <input
            type="number"
            name="revNo"
            value={formData.revNo}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Date:</label>
          <input
            type="date"
            name="date"
            value={formData.date}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Request No:</label>
          <input
            type="text"
            name="requestNo"
            value={formData.requestNo}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            placeholder="YYYYMMDD01"
          />
        </div>

        <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700">Location:</label>
  <input  type='text' name="location" value={formData.location} onChange={handleChange} className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
  />
</div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Apparatus required for work:</label>
          <p>Transformer</p>
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Equipment Identification:</label>
          <select
            name="equipmentId"
            value={formData.equipmentId}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option value="">Select Equipment</option>
            {/* Add options here */}
          </select>
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Nature and extent of work to be done:</label>
          <textarea
            name="workDescription"
            value={formData.workDescription}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          ></textarea>
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Points of Isolation:</label>
          <input
            type="text"
            name="pointsOfIsolation"
            value={formData.pointsOfIsolation}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            readOnly
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Primary Earths:</label>
          <input
            type="text"
            name="primaryEarths"
            value={formData.primaryEarths}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            readOnly
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Additional Earths at:</label>
          <input
            type="text"
            name="additionalEarths"
            value={formData.additionalEarths}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Safety document to be issued:</label>
          <p>same as per pdf</p>
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Risk of Tripping Condition:</label>
          <p>same as per pdf</p>
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Circuits Affected:</label>
          <textarea
            name="circuitsAffected"
            value={formData.circuitsAffected}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          ></textarea>
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Time and duration of work:</label>
          <input
            type="text"
            name="workDuration"
            value={formData.workDuration}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            disabled
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Can the Apparatus be Restored Daily:</label>
          <p>same as per pdf</p>
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Notice period required for re-energizing in case of emergency:</label>
          <input
            type="time"
            name="noticePeriod"
            value={formData.noticePeriod}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Work requested by (CRE):</label>
          <select
            name="workRequestedBy"
            value={formData.workRequestedBy}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option value="">Select CRE</option>
            {/* Add options here */}
          </select>
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Tel:</label>
          <input
            type="text"
            name="tel"
            value={formData.tel}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            readOnly
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Authorized Person:</label>
          <select
            name="authorizedPerson"
            value={formData.authorizedPerson}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option value="">Select Authorized Person</option>
            {/* Add options here */}
          </select>
        </div>

        {/* Approval Section */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Approval:</label>
          <select
            name="approval"
            value={formData.approval}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option value="">Select Approval</option>
            <option value="Approved">Approved</option>
            <option value="Rejected">Rejected</option>
            <option value="Pending">Pending</option>
          </select>
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Approved by:</label>
          <select
            name="approvedBy"
            value={formData.approvedBy}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option value="">Select Approver</option>
            {/* Add options here */}
          </select>
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Date:</label>
          <input
            type="date"
            name="approvalDate"
            value={formData.approvalDate}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Comments:</label>
          <textarea
            name="comments"
            value={formData.comments}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          ></textarea>
        </div>

        {/* Shift Control Manager Approval */}
        <h2 className="text-lg font-bold mb-2">Shift Control Manager Approval</h2>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Work approved by:</label>
          <p>skip</p>
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Sign:</label>
          <p>skip</p>
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Date:</label>
          <p>skip</p>
        </div>

        {/* SCADA Engineer Section */}
        <h2 className="text-lg font-bold mb-2">SCADA Engineer Approval</h2>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Work approved:</label>
          <p>same as per pdf</p>
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Comments:</label>
          <textarea
            name="scadaComments"
            value={formData.scadaComments}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          ></textarea>
        </div>

        {/* Control Room Engineer Section */}
        <h2 className="text-lg font-bold mb-2">Control Room Engineer</h2>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Signature:</label>
          <input
            type="text"
            name="controlRoomEngineerSignature"
            value={formData.controlRoomEngineerSignature}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Date:</label>
          <input
            type="date"
            name="controlRoomEngineerDate"
            value={formData.controlRoomEngineerDate}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        {/* Shift Control Manager Section */}
        <h2 className="text-lg font-bold mb-2">Shift Control Manager</h2>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Signature:</label>
          <input
            type="text"
            name="shiftControlManagerSignature"
            value={formData.shiftControlManagerSignature}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Date:</label>
          <input
            type="date"
            name="shiftControlManagerDate"
            value={formData.shiftControlManagerDate}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
         <button type='submit' className="bg-blue-500 w-[100%] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
         {loading? "loading":"submit"}         
        </button>
      </form>
    </div>
  );
};

export default FormComponent;

